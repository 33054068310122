<template>
  <div>
    <div v-if="editable" class="col float-right">
      <q-btn-group outline>
        <!-- [S]결재관련 버튼 -->
        <!-- sysApprovalRequestId: 상세조회시 관련 결재요청일련번호 확인 -->
        <!-- popupParam.isApprContent: 결재요청/결재처리 팝업의 component에 해당 페이지 호출시 true -->
        <!-- approvalStatusCd: 결재요청건에 대한 상태코드 -->
        <!-- popupParam.approvalDocType: TODO(결재할문서에서 상세페이지호출시) -->
        <c-appr-btn 
          ref="appr-btn"
          name="vendor-assess-appr-btn"
          :editable="editable && param.selfInspectionResultId != ''"
          :approvalInfo="approvalInfo"
          @beforeApprAction="saveDataAppr"
          @callbackApprAction="approvalCallback"
          @requestAfterAction="getApprAfter"
        />
        <!-- [E]]결재관련 버튼 -->
        <!-- <c-btn
          v-show="editable && param.selfInspectionResultId && param.selfInspectionStatusCd == 'SISC000002'"
          :url="saveUrl"
          :isSubmit="isSave"
          :param="data"
          :mappingType="mappingType"
          label="실행완료"
          icon="assignment_turned_in"
          color="orange-6"
          @beforeAction="saveDataEnd"
          @btnCallback="saveCallbackEnd" /> -->
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="param"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazop-plan',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',  // 평가항목 개정번호
        count: 0,
      }),
    },
  },
  computed: {
    tabItems() {
      return [
        { key: uid(), name: 'run', icon: 'check_circle_outline', label: '감사실행', component: () => import(`${'./runExec.vue'}`) },
        { key: uid(), name: 'runInfo', icon: 'info', label: '감사 의견 및 요약정보', component: () => import(`${'./runInfo.vue'}`) },
        { key: uid(), name: 'doc', icon: 'checklist', label: '관련자료', component: () => import(`${'./runDoc.vue'}`) },
      ]
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.selfInspectionStatusCd === 'SISC000002', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000006', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          selfInspectionResultId: this.data.selfInspectionResultId,
          sysRevision: this.data.sysRevision,
          isApprContent: true
        },
        approvalRequestName: '자체감사 ['+this.data.selfInspectionTitle+']', // 결재요청명 (문서 title)
        approvalConnId: this.data.selfInspectionResultId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
  },
  data() {
    return {
      isApproval: false,
      data: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        chgUserId: '',
        sysApprovalRequestId: '',
        approvalTypeCd: '',
        approvalStatusCd: '',
        approvalStatusName: '',
      },
      saveUrl: transactionConfig.sop.pca.result.info.planend.url,
      infoUrl: '',
      updateUrl: '',
      editable: true,
      splitter: 5,
      mappingType: 'PUT',
      isSave: false,
      tab: 'run',
      height: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.height = (window.innerHeight - 150) + 'px';
      this.editable = this.$route.meta.editable;
      this.infoUrl = selectConfig.sop.pca.result.info.get.url;
      this.updateUrl = transactionConfig.sop.pca.result.info.update.url;
      this.getInfo();
    },
    getInfo() {
      this.$http.url = this.infoUrl;
      this.$http.type = 'GET';
      this.$http.param = {selfInspectionResultId: this.param.selfInspectionResultId};
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.sysRevision = this.data.sysRevision + '';
        this.param.selfInspectionStatusCd = this.data.selfInspectionStatusCd;
      },);
    },
    getApprAfter() {
      this.getInfo();
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청을 진행하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    saveDataEnd() {
      let saveMsg = '감사를 완료하시겠습니까?';
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: saveMsg,
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.selfInspectionResultId = this.param.selfInspectionResultId;
          this.data.selfInspectionStatusCd = 'SISC000003';
          this.data.regUserId = this.$store.getters.user.userId;
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallbackEnd() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // stepper 이동
      this.$emit('emitStep', {
        name: 'currentStep',
        param: 'SISC000003'
      })
    },
  }
};
</script>
